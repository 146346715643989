// import logo from './logo.svg';
// import './css/owl.carousel.css';
// import './css/owl.carousel.min.css';
// import './js-plugins/jquery/jquery-2.2.4.min.js'
// import './js-plugins/plugins.js'
// import './js-plugins/active.js'
// import './js-plugins/jquery.slicknav.js'
// import './js-plugins/owl.carousel.min.js'
// import './js-plugins/main.js'
import './components/pagelayout/css/index.css';
import './css/owl.carousel.css'
// import './js'
import './App.css';
import React, { Suspense } from 'react'

import { BrowserRouter, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuthContext';

import MyComponent from 'react-fullpage-custom-loader'

const DefaultLayout = React.lazy(() => import('./Layout/Index'))

function App() {
  const { authIsReady }  = useAuth()

  return (
    <div className="App">
      {!authIsReady && (<MyComponent 
        sentences={[]}
        loaderType={'ball-triangle-path'}
      />)}
      {authIsReady && <BrowserRouter>
        <Suspense>
          <Routes>
              <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>}
    </div>
  );
}

export default App;
