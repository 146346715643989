import  { createContext, useEffect, useReducer } from 'react'
import axios from 'axios'
import { appURL } from '../env'
import { useNavigate } from 'react-router'

export const AuthContext =  createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload }
        case 'LOGOUT':
            return { ...state, user: null }
        case 'AUTH_READY':
            return { ...state, user: action.payload, authIsReady: true }
        default: 
            return state
    }
}

// eslint-disable-next-line
export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false
    })

    useEffect(() => {
        const userDetails = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {access_token: null}

        const checkUser = () => {
            axios.post(`${appURL}/api/check_user`, userDetails, {
                    headers: {
                        "Accept": "application/json",
                        "Authorization" : `Bearer ${userDetails.access_token}`
                    },
                })
                .then(({data})=>{
                    if(data === ' empty') {
                        localStorage.removeItem('userData')
                    }
                    const dt = data === ' empty' ? null : data
                    dispatch({ type: 'AUTH_READY', payload: dt })

                })
        }
        checkUser()
    }, [])

    return (
        <AuthContext.Provider value={{...state,  dispatch }}>
            { children }
        </AuthContext.Provider>
    )
}

